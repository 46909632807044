import * as React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import EstatePage from "../components/estate"
import languages from "../utils/enums/languages"

const Estates = ({ location, pageContext: { translate } }) => (
  <Layout
    secondary
    pageContext={{ translate }}
    path="partners"
    location={location}
    SEO={({ data }) => (
      <SEO
        title={data.t?.estate_page_title}
        description={data.t?.estates_metad}
        keywords={data.t?.keywords_estates}
        languages={[
          ...languages.map(l => {
            return {
              hrefLang: l || 'en',
              href: `${process.env.GATSBY_SITE_URL}${
                l ? "/" + l + "/" : "/"
              }estates/`,
            }
          }),
          {
            hrefLang: "x-default",
            href: `${process.env.GATSBY_SITE_URL}/estates/`,
          },
        ]}
      />
    )}
  >
    <>
      {
        <>
          <EstatePage pageContext={{ translate }} />
        </>
      }
    </>
  </Layout>
)

export default Estates
