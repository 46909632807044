import * as React from "react"
import { Link } from "gatsby"
import Whitepaper from "../models/whitepaper"
const Steps = ({
  heading,
  description,
  data,
  btnText,
  btnLink,
  model,
  pageContext: { translate },
}) => {
  return (
    <>
      {
        <div className="steps">
          <div className="content">
            <div className="headings">
              <h2 className="heading-h2 semi text-darker">{heading}</h2>
              {description && (
                <p className="paragraph-p1  text-darker">{description}</p>
              )}
              {btnText &&
                (model === "contact" ? (
                  <Whitepaper
                    pageContext={{ translate }}
                    btnText={btnText}
                    styler="greenbtn"
                    title={translate.t?.contact_form}
                    description={translate.t?.contact_form_description}
                    type="contact"
                    submitBtnText={translate.t?.submit}
                  />
                ) : model === "download" ? (
                  <Whitepaper
                    pageContext={{ translate }}
                    btnText={btnText}
                    styler="greenbtn"
                    title={translate.t?.contact_form}
                    description={translate.t?.contact_form_description}
                    type="contact"
                    submitBtnText={translate.t?.submit}
                  />
                ) : (
                  <Link to={btnLink}>
                    <button className="btndefault greenbtn">{btnText}</button>
                  </Link>
                ))}
            </div>
            <div className="block-list">
              {data?.map((content, counter) => (
                <div key={counter} className="box">
                  <div className="detail">
                    {!!content.pagename && (
                      <div className="pagename">{content.pagename}</div>
                    )}
                    {!!content.title && (
                      <h3 className=" heading-h24  semi text-darker">
                        {content.title}
                      </h3>
                    )}
                    <p className="paragraph-p16  text-medium">
                      {content.description}
                    </p>
                    {!!content.btnText && (
                      <Link to={content.btnLink}>
                        <button className="btndefault greenbtn">
                          {content.btnText}
                        </button>
                      </Link>
                    )}
                  </div>
                  <img src={content.img} alt="Steps" />
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Steps
