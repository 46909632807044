import * as React from "react"
import Hero from "../roaster/hero"
import Oppurtunity from "../roaster/oppurtunity"
import TeamWork from "../roaster/teamwork"
import Join from "../roaster/join"
import Experience from "../roaster/experience"
import Services from "../roaster/services"
import Steps from "../traceability/steps"
import Origin from "../roaster/origin"

import Luiz from "../../assets/images/Ama_Luiz.webp"
import heroimage from "../../assets/images/estatehero.png"
import f1 from "../../assets/images/icons/noun-global-3394124.svg"
import f2 from "../../assets/images/icons/noun-invoice-3394132.svg"
import f3 from "../../assets/images/icons/group-28.svg"
import f4 from "../../assets/images/icons/noun-mobile-3394126.svg"
import f5 from "../../assets/images/icons/group-39.svg"
import f6 from "../../assets/images/icons/noun-tablet-3394141.svg"
import step1 from "../../assets/images/Messaging.gif"
import step2 from "../../assets/images/setup-services.gif"
import step3 from "../../assets/images/coffee-lab.gif"
import o1 from "../../assets/images/icons/o1.svg"
import o2 from "../../assets/images/icons/o2.svg"
import o3 from "../../assets/images/icons/o3.svg"
import o4 from "../../assets/images/icons/o4.svg"
import brand from "../../assets/images/icons/your-brand.svg"
import time from "../../assets/images/icons/time.svg"
import communication from "../../assets/images/icons/community-initiatives.svg"
import chart4 from "../../assets/images/icons/team4.svg"
import n1 from "../../assets/images/n1.jpg"
import n2 from "../../assets/images/n2.jpg"
import n3 from "../../assets/images/n3.jpg"
import n4 from "../../assets/images/n4.jpg"
import n5 from "../../assets/images/n5.jpg"
import contentHeroImage from "../../assets/images/hero-content-bg.svg"

const FacilatatorPage = ({ pageContext: { translate } }) => {
  const originData = [
    {
      img: n1,
      title: translate.t?.end_consumer,
      description: translate.t?.feedback_high_traceable,
      link: "",
    },
    {
      img: n2,
      title: translate.t?.estate,
      description: translate.t?.communicate_develop_relationship,
      link: "",
    },
    {
      img: n3,
      title: translate.t?.milling,
      description: translate.t?.understand_precise_data,
      link: "",
    },
    {
      img: n4,
      title: translate.t?.roaster,
      description: translate.t?.purchase_sell_coffee_own_terms,
      link: "",
    },
    {
      img: n5,
      title: translate.t?.hospitality,
      description: translate.t?.communicate_develop_relationship,
      link: "",
    },
  ]

  const dataOppurtunity = [
    {
      icon: o1,
      title: translate.t?.brand_equity,
      description: translate.t?.estate_equity_loyalty,
    },
    {
      icon: o2,
      title: translate.t?.advantage,
      description: translate.t?.additional_rev_streams,
    },
    {
      icon: o3,
      title: translate.t?.build_relationships,
      description: translate.t?.direct_relation_stakeholders,
    },
    {
      icon: o4,
      title: translate.t?.efficiency,
      description: translate.t?.digitally_transform_business,
    },
  ]

  const dataTeam = [
    {
      icon: time,
      title: translate.t?.time_investment,
      description: translate.t?.spend_time_understanding_values,
    },
    {
      icon: communication,
      title: translate.t?.community_initiatives,
      description: translate.t?.estate_support_local_community,
    },
    {
      icon: brand,
      title: translate.t?.build_your_brand,
      description: translate.t?.eraofwe_believes_brand_development,
    },
    {
      icon: chart4,
      title: translate.t?.approval_process,
      description: translate.t?.estates_receive_approval_verification,
    },
  ]

  const dataSteps = [
    {
      img: step1,
      title: translate.t?.direct_relations_trans_data,
      description: translate.t?.info_landlots_offerings,
      pagename: translate.t?.building_relationships,
    },

    {
      img: step2,
      title: translate.t?.easy_access_additional,
      description: translate.t?.get_connected_fc,
      pagename: translate.t?.fc_services,
    },
    {
      img: step3,
      title: translate.t?.tell_stories_global_stage,
      description: translate.t?.access_global_network_rc,
      pagename: translate.t?.global_exposure,
    },
  ]

  const dataService = [
    {
      title: translate.t?.movement_of_winners,
      description: translate.t?.no_signup_costs,
      img: f1,
      details: [
        translate.t?.invites_members_csc_prosper,
        translate.t?.help_eraofwe_community_thrive,
      ],
    },
    {
      title: translate.t?.set_your_price,
      description: translate.t?.set_your_price_brand_standards,
      img: f2,
      details: [translate.t?.we_provide_dm_tools, translate.t?.room_to_profit],
    },
    {
      title: translate.t?.ec_exp,
      description: translate.t?.make_people_love,
      img: f3,
      details: [
        translate.t?.we_bond_producers,
        translate.t?.get_insight_popular_favorites,
      ],
    },
    {
      title: translate.t?.friendly_communication,
      description: translate.t?.easy_global_comm_large,
      img: f4,
      details: [
        translate.t?.no_language_barrier,
        translate.t?.our_platform_encourages,
      ],
    },
    {
      title: translate.t?.market_your_brand,
      description: translate.t?.several_dm_features,
      img: f5,
      details: [
        translate.t?.when_you_build_profile,
        translate.t?.create_global_reach,
      ],
    },
    {
      title: translate.t?.digital_transformation,
      description: translate.t?.increase_efficiency,
      img: f6,
      details: [
        translate.t?.our_platform_helps_onboard,
        translate.t?.moving_businesses_digital,
      ],
    },
  ]

  return (
    <>
      {
        <div className="facilitator estate">
          <div className="roaster_page">
            <div className="content">
              <Hero
                pageContext={{ translate }}
                pagename="Era of We For Producers"
                title={translate.t?.create_grow_brand}
                description={translate.t?.gain_global_recognition}
                btntext={translate.t?.book_a_demo}
                img={heroimage}
                btnLink=""
                model="contact"
              />
              <Oppurtunity
                pageContext={{ translate }}
                heading={translate.t?.new_opp_optimistic_capitalism}
                btnText=""
                btnLink=""
                isEstate={true}
                data={dataOppurtunity}
              />
              <Origin
                pageContext={{ translate }}
                heading={translate.t?.show_others_c2c}
                description={translate.t?.get_insights_land_lots}
                btnText={translate.t?.how_it_works}
                btnLink={`${
                  translate.currentLang
                    ? "/" + translate.currentLang + "/"
                    : "/"
                }traceability/`}
                data={originData}
              />
              <Experience
                pageContext={{ translate }}
                title={translate.t?.exp_roasters_love}
                btnText={translate.t?.view_our_community}
                btnLink={`${
                  translate.currentLang
                    ? "/" + translate.currentLang + "/"
                    : "/"
                }community/`}
                img={Luiz}
                name="Luiz Paulo Dias Pereira Filho"
                designation="Ama Coffee Producer"
                pagetitle=""
                description={translate.t?.tbh_eraofwe_newera}
              />
              <div className="services-new estatesPage">
                <Services
                  title={translate.t?.benefits_for_estates}
                  description={translate.t?.eraofwe_countless_opp}
                  data={dataService}
                  btnText={translate.t?.contact_us}
                  btnLink={`${
                    translate.currentLang
                      ? "/" + translate.currentLang + "/"
                      : "/"
                  }contact/`}
                  pageContext={{ translate }}
                />
              </div>
              <div className="secondhero">
                <Hero
                 pageContext={{ translate }}
                  pagename={translate.t?.hand_in_hand_initiative}
                  subTitle={translate.t?.we_empower_farmers}
                  description={translate.t?.eraofwe_partners_lsf}
                  btntext={translate.t?.book_a_demo}
                  btnLink=""
                  model="contact"
                  img={contentHeroImage}
                />
              </div>
              <div className="traceability_page">
                <Steps
                  pageContext={{ translate }}
                  heading={translate.t?.relationships_driven_data}
                  description=""
                  btnText={translate.t?.book_a_demo}
                  btnLink=""
                  data={dataSteps}
                  model="contact"
                />
              </div>
              <TeamWork
                pageContext={{ translate }}
                heading={translate.t?.great_teamwork_commitment}
                btnText={translate.t?.download_whitepaper}
                btnLink=""
                data={dataTeam}
                modal="download"
                flex={true}
              />
              <div className="joiner-second">
                <Join pageContext={{ translate }} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default FacilatatorPage
